import UserForm from '../../components/Form';
import './index.css';

function Home() {
  return (
    <div className="container">
        <div className='d-flex justify-content-center align-items-center home-holder'>
            <UserForm/>
        </div>
    </div>
  );
}

export default Home;
