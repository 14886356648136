import React, { useState } from 'react';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import Spinner from '../static/spinner.svg'

const UserForm = () => {
  const [username, setUsername] = useState('');
  const [accountId, setAccountId] = useState('');
  const [fileId, setFileId] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userVerified, setUserVerified] = useState(false);


  const verifyUser = async () => {

    // Validate form fields
    if (!username) {
      setError('Username is required!');
      setIsLoading(false);
      return;
    }

    // Call the API to get the PDF
    try {
      const response = await fetch(`https://vjp5xfv5obsbb5wqe5j3ffjoaq0vhsaq.lambda-url.ap-southeast-1.on.aws/?mode=read&key=${username}`);
      
      if (!response.ok) {
        const errorMessage = await response.text()

            if(errorMessage){
                throw new Error(errorMessage);
            }else{
                throw new Error('Invalid Username');
            }
       
      }else{
        setUserVerified(true)
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsLoading(true);

    if (!userVerified){
      return verifyUser()
    }

    // Validate form fields
    if (!username || !accountId || !fileId) {
      setError('All fields are required!');
      setIsLoading(false);
      return;
    }

    // Call the API to get the PDF
    try {
      const response = await fetch(`https://otsq6qochzxftxfotzfyajqqry0nbzvw.lambda-url.ap-southeast-1.on.aws/?key=${username}&cookie=${accountId}&id=${fileId}`);
      
      
      if (!response.ok) {
        const errorMessage = await response.json()
        const jsonMessage = JSON.parse(errorMessage)

            if(jsonMessage){
              if(jsonMessage.error){
                throw new Error(jsonMessage.error.message);
              }else{
                  throw new Error(errorMessage);
              }
            }else{
                throw new Error('Failed to fetch PDF');
            }
       
      }else{
      const pdfBlob = await response.blob();

      // Download the PDF
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfBlob);
      link.download = `${fileId}.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSuccess("PDF downloaded successfully")
      }

    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className='user-form'>
    <Card.Header>Coursehero Downloader</Card.Header>
    <Card.Body>
    <Form onSubmit={handleSubmit} >
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form.Group controlId="formUsername">
        <Form.Label className='text-start w-100 mt-3'>Username:</Form.Label>
        <Form.Control disabled={userVerified} type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </Form.Group>
      {userVerified?
      <>
      <Form.Group controlId="formAccountId">
        <Form.Label className='text-start w-100 mt-3'>Account ID:</Form.Label>
        <Form.Control type="text" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
      </Form.Group>
      <Form.Group controlId="formFileId">
        <Form.Label className='text-start w-100 mt-3'>File ID:</Form.Label>
        <Form.Control type="text" value={fileId} onChange={(e) => setFileId(e.target.value)} />
      </Form.Group>
      </>
      :null}
      <div className='d-flex justify-content-center'>
      <Button variant="primary" type="submit" disabled={isLoading} className='mt-3' size='lg'>
        {isLoading ?
        <img src={Spinner} className="spinner"/> : 'Submit'}
      </Button>
      </div>
    </Form>
    </Card.Body>
  </Card>
 
  );
};

export default UserForm;



